import React from "react";
import PropTypes from "prop-types";

import tw, { css } from "twin.macro";

import { graphql } from "gatsby";

import { Layout } from "@components/layout";
import { Heading, Textbox } from "@components/shared";

const AboutPage = ({ data }) => (
  <Layout title="O nas">
    <Heading tw="text-center mb-12">O nas</Heading>
    <Textbox
      blocks={data.sanityAboutPage._rawContent}
      css={css`
        & p {
          ${tw`w-container md:w-7/10 xl:w-7/12 mx-auto`}
        }
      `}
    />
  </Layout>
);

AboutPage.propTypes = {
  data: PropTypes.shape({
    sanityAboutPage: PropTypes.shape({
      _rawContent: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query AboutPageQuery {
    sanityAboutPage {
      _rawContent
    }
  }
`;

export default AboutPage;
